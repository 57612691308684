import React, { useState } from "react";
import axios from "axios";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Contact = () => {
  const [submitted, setSubmitted] = useState(false);
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    await sendMessage(formData);
    setSubmitted(true);
  };

  const handleChange = evt => {
    const { name, value } = evt.target;
    setFormData(fData => ({
      ...fData,
      [name]: value
    }));
  };

  const sendMessage = async (formData) => {
    const options = { headers: {'Content-Type' : 'application/json'} };
    const apiUrl = 'https://trm8aepyo8.execute-api.us-east-1.amazonaws.com/prod/contact';
    return await axios.post(apiUrl, formData, options);
  };

  return (
    <Layout>
      <SEO title="Contact" />
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <h1 className="mb-4">Let's <span className="primary">chat</span>.</h1>
            <p hidden={!submitted}>Thanks for reaching out! I'll get back to you soon.</p>

            <form hidden={submitted} onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <input type="text" 
                         className="form-control" 
                         name="name" 
                         value={formData.name} 
                         onChange={handleChange} 
                         placeholder="Name" 
                         required />
                </div>
                <div className="form-group col-md-6">
                  <input type="email" 
                         className="form-control" 
                         name="email" 
                         value={formData.email}
                         onChange={handleChange} 
                         placeholder="Email" 
                         required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <textarea className="form-control" 
                            rows="3" 
                            name="message"
                            value={formData.message}
                            onChange={handleChange} 
                            placeholder="What would you like to chat about?" 
                            required>
                  </textarea>
                </div>
              </div>
              <div className="form-row">
                <button type="submit" className={`btn btn-primary ml-auto ${ sending ? 'sending' : '' }`} disabled={sending}>
                  { sending ? 'Sending..' : 'Send Message' }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default Contact;
